import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  AlertTitle,
  Grid
} from '@mui/material';
import {
  ExpandMore,
  ShowChart as ShowChartIcon,
  DescriptionOutlined,
  Explore,
  UnfoldLess,
  UnfoldMore,
  TrendingUp,
  MapsHomeWork,
  Assessment,
  Add as AddIcon,
  DonutSmallOutlined
} from '@mui/icons-material';
import { formatSchoolName } from '../../utils/nameFormatter';
import { apiFetch } from '../../utils/api';
import { Link as RouterLink } from 'react-router-dom';

// Lazy load the chart component
const EnrollmentProjectionDashboard = React.lazy(() => import('./EnrollmentProjectionDashboard'));
const EnrollmentTargetAssessment = React.lazy(() => import('./EnrollmentTargetAssessment'));
const StrategyImpactAnalysis = React.lazy(() => import('./StrategyImpactAnalysis'));

const GRADES = ['Kindergarten', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

function ProjectionsExplorer() {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [enrollmentData, setEnrollmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const navigate = useNavigate();
  const [timeframe, setTimeframe] = useState('5-year');
  const [view, setView] = useState('school-level');
  const [selectedGrades, setSelectedGrades] = useState(['total']);
  const [availableGrades, setAvailableGrades] = useState(['total']);
  const [expandedSections, setExpandedSections] = useState({
    introduction: true,
    filters: true,
    enrollment: false,
    scenarios: false,
    risk: false,
    impact: false,
    actions: false
  });
  const [openDataDialog, setOpenDataDialog] = useState(false);
  const [hasSkippedDataEntry, setHasSkippedDataEntry] = useState(false);
  const [schoolsLoading, setSchoolsLoading] = useState(true);
  const [adjustedProjections, setAdjustedProjections] = useState(null);
  const [enrollmentTargets, setEnrollmentTargets] = useState({});

  const fetchSchools = useCallback(async () => {
    setSchoolsLoading(true);
    try {
      const data = await apiFetch('/schools');
      const uniqueSchools = Array.from(new Map(data.map(school => [school.ncessch, school])).values())
        .sort((a, b) => formatSchoolName(a.name).localeCompare(formatSchoolName(b.name)));
      setSchools(uniqueSchools);
    } catch (err) {
      console.error('Error fetching schools:', err);
      setError('Failed to load school data. Please try again later.');
      if (err.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    } finally {
      setSchoolsLoading(false);
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchSchools();
  }, [fetchSchools]);

  useEffect(() => {
    setAdjustedProjections(null);
  }, [selectedSchool]);

  const handleSchoolChange = async (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      setSelectedSchool({...school, name: formatSchoolName(school.name)});
      setEnrollmentData(null);
      setDataLoading(true);
      
      try {
        const data = await apiFetch(`/school/${schoolId}/enrollment-projections`);
        setEnrollmentData(data);
        
        // Extract available grades
        const grades = new Set();
        Object.values(data.actual_enrollment || {}).forEach(yearData => {
          Object.keys(yearData || {}).forEach(grade => {
            // Normalize grade values
            let normalizedGrade;
            if (grade === '0' || grade === 'K') {
              normalizedGrade = 'Kindergarten';
            } else if (!['PK', 'Pre-K', 'PreK', 'Pre-Kindergarten'].includes(grade)) {
              // For numeric grades, make sure they're strings without "Grade " prefix
              normalizedGrade = grade.replace('Grade ', '');
            }
            
            if (normalizedGrade) {
              grades.add(normalizedGrade);
            }
          });
        });
        
        // Filter and sort available grades based on the GRADES constant order
        const sortedGrades = GRADES.filter(grade => grades.has(grade));
        
        setAvailableGrades(sortedGrades);
        
        // Set initial grade selection
        if (view === 'grade-level' && sortedGrades.length > 0) {
          setSelectedGrades([sortedGrades[0]]);
        } else {
          setSelectedGrades(['total']);
        }
  
        if (!data?.based_on_user_data) {
          setOpenDataDialog(true);
        }
  
        setExpandedSections(prev => ({
          ...prev,
          scenarios: true
        }));
      } catch (err) {
        console.error('Error fetching projections:', err);
        setError(`Failed to fetch enrollment projections: ${err.message}`);
        if (err.message === 'Session expired. Please log in again.') {
          navigate('/login');
        }
      } finally {
        setDataLoading(false);
      }
    }
  };

  const sortGrades = (grades) => {
    return grades.sort((a, b) => {
      // Special case for Kindergarten - always first
      if (a === 'K') return -1;
      if (b === 'K') return 1;
  
      // Special case for high school grades - always last in order
      const highSchoolGrades = ['10', '11', '12'];
      const aIsHS = highSchoolGrades.includes(a);
      const bIsHS = highSchoolGrades.includes(b);
  
      if (aIsHS && !bIsHS) return 1;
      if (!aIsHS && bIsHS) return -1;
      if (aIsHS && bIsHS) return parseInt(a) - parseInt(b);
  
      // Regular numeric comparison for other grades
      return parseInt(a) - parseInt(b);
    });
  };

  const handleGradeChange = (event) => {
    const { value } = event.target;
    if (view === 'school-level') {
      setSelectedGrades(['total']);
    } else {
      // Filter out 'total' and ensure at least one grade is selected
      const newSelection = Array.isArray(value) ? value : [value];
      if (newSelection.length === 0) {
        // If user tries to deselect all grades, keep the last selected grade
        return;
      }
      // Sort the selected grades using the same sorting function
      setSelectedGrades(sortGrades(newSelection));
    }
  };

  const handleViewChange = (event, newView) => {
    if (newView) {
      setView(newView);
      if (newView === 'grade-level' && availableGrades.length > 0) {
        const validGrades = availableGrades.filter(grade => grade !== 'total');
        if (validGrades.length > 0) {
          setSelectedGrades([validGrades[0]]);
        }
      } else if (newView === 'school-level') {
        setSelectedGrades(['total']);
      }
    }
  };

  useEffect(() => {
    if (view === 'school-level') {
      setSelectedGrades(['total']);
    }
  }, [view]);

  const handleAccordionChange = (section) => (event, isExpanded) => {
    setExpandedSections({
      ...expandedSections,
      [section]: isExpanded
    });
  };

  const handleExpandAll = () => {
    const allExpanded = Object.fromEntries(
      Object.keys(expandedSections).map(key => [key, true])
    );
    setExpandedSections(allExpanded);
  };

  const handleCollapseAll = () => {
    const allCollapsed = Object.fromEntries(
      Object.keys(expandedSections).map(key => [key, false])
    );
    setExpandedSections(allCollapsed);
  };
  
  const handleDataEntryRedirect = () => {
    navigate('/data-entry');
    setOpenDataDialog(false);
  };
  
  const handleSkipDataEntry = () => {
    setHasSkippedDataEntry(true);
    setOpenDataDialog(false);
    setExpandedSections(prev => ({
      ...prev,
      scenarios: true
    }));
  };

  const isAllExpanded = Object.values(expandedSections).every(value => value === true);

  const renderRiskAssessment = () => {
    if (!selectedSchool || !enrollmentData) {
      return (
        <Typography>
          Please select a school and enter enrollment data to view risk assessment.
        </Typography>
      );
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Suspense fallback={<CircularProgress />}>
          <EnrollmentTargetAssessment 
            enrollmentData={enrollmentData}
            timeframe={timeframe}
            selectedGrades={selectedGrades}
            view={view}
            onTargetsChange={setEnrollmentTargets}
          />
        </Suspense>
      </Box>
    );
  };

  if (isLoading && !selectedSchool) {
    return (
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  const renderProjectionsContent = () => {
    if (dataLoading) {
      return <Skeleton variant="rectangular" height={400} />;
    }
  
    if (!enrollmentData) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Typography>No projections available</Typography>
          <Button
            color="primary"
            component={RouterLink}
            to="/data-entry"
            startIcon={<AddIcon />}
            variant="contained"
          >
            Add Enrollment Data
          </Button>
        </Box>
      );
    }
  
    const displayData = adjustedProjections ? {
      ...enrollmentData,
      projections: adjustedProjections
    } : enrollmentData;
  
    return (
      <EnrollmentProjectionDashboard 
        school={selectedSchool}
        enrollmentData={displayData}
        selectedGrades={selectedGrades}
        timeframe={timeframe}
        view={view}
      />
    );
  };

  return (
    <>
      {/* Main Content Container */}
      <Box sx={{ 
        px: { xs: 1, sm: 2 }, // Horizontal padding
        pb: 4,
        position: 'relative',
        width: '100%'
      }}>
        <Container maxWidth="xl" sx={{ py: 3 }}> {/* Changed mt to py for consistent spacing */}
          {/* Title */}
          <Box sx={{ mb: 3 }}> {/* Reduced margin bottom */}
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Projections Explorer
            </Typography>
          </Box>
  
        {/* Introduction Text - Only shown before school selection */}
        {!selectedSchool && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="body1" paragraph>
              Follow these steps to analyze and plan your school's enrollment:
            </Typography>
            <Box component="ol" sx={{ mb: 2 }}>
              <Typography component="li">
                <Box component="span" sx={{ fontWeight: 'bold' }}>Start with Historical Trends:</Box> Explore enrollment projections based on your school's historical data to understand baseline expectations
              </Typography>
              <Typography component="li">
                <Box component="span" sx={{ fontWeight: 'bold' }}>Assess Your Risk:</Box> Compare your enrollment targets against projected scenarios to identify potential gaps and challenges
              </Typography>
              <Typography component="li">
                <Box component="span" sx={{ fontWeight: 'bold' }}>Test Different Strategies:</Box> Model how various recruitment and retention approaches could impact your enrollment outcomes
              </Typography>
              <Typography component="li">
                <Box component="span" sx={{ fontWeight: 'bold' }}>Take Action:</Box> Access additional planning tools and resources to implement your strategies
              </Typography>
            </Box>
            <Typography variant="body1" paragraph>
              <Box component="span" sx={{ fontWeight: 'bold' }}>Before you begin:</Box> Select a school to view its enrollment data and projections.
            </Typography>
            <Box sx={{ mb: 4 }}>
              <Button
                component={RouterLink}
                to="/methodology"
                color="primary"
                sx={{ textTransform: 'none' }}
              >
                Want to learn more about our projection methodology? Visit our documentation page
              </Button>
            </Box>
  
            {/* School Selection initially below intro text */}
                <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel id="school-select-label">Select a School</InputLabel>
                <Select
                    labelId="school-select-label"
                    id="school-select"
                    value={selectedSchool ? selectedSchool.ncessch : ''}
                    label="Select a School"
                    onChange={handleSchoolChange}
                    disabled={schoolsLoading}
                >
                    {schools.map((school) => (
                    <MenuItem key={school.ncessch} value={school.ncessch}>
                        {formatSchoolName(school.name)}
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Box>
            )}
  
       {/* Sticky Controls Section - Only shown after school selection */}
      {selectedSchool && (
        <Box
          sx={{
            position: 'sticky',
            top: { xs: 56, sm: 64 }, // Account for navbar height
            backgroundColor: 'background.default',
            backdropFilter: 'blur(8px)',
            zIndex: 1200, // Increased z-index to ensure it stays above other content
            borderBottom: '1px solid',
            borderColor: 'divider',
            pt: 2,
            pb: 2,
            mb: 3,
            mx: { xs: -2, sm: -3 }, // Negative margin to extend full width
            px: { xs: 2, sm: 3 }, // Padding to maintain content alignment
            // Add these new styles
            left: 0,
            right: 0,
            width: '100%',
            display: 'block'
          }}
        >
          <Container maxWidth="xl" disableGutters>
            {/* Controls Row */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 2,
              flexWrap: 'wrap'
            }}>
              <FormControl sx={{ flexGrow: 1, minWidth: 200 }}>
                <InputLabel size="small">Select a School</InputLabel>
                <Select
                  value={selectedSchool ? selectedSchool.ncessch : ''}
                  label="Select a School"
                  onChange={handleSchoolChange}
                  size="small"
                >
                  {schools.map((school) => (
                    <MenuItem key={school.ncessch} value={school.ncessch}>
                      {formatSchoolName(school.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <ToggleButtonGroup
                value={timeframe}
                exclusive
                onChange={(e, value) => value && setTimeframe(value)}
                size="small"
              >
                <ToggleButton value="1-year">1-Year</ToggleButton>
                <ToggleButton value="5-year">5-Year</ToggleButton>
              </ToggleButtonGroup>

              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewChange}
                size="small"
              >
                <ToggleButton value="school-level">School-Level</ToggleButton>
                <ToggleButton value="grade-level">Grade-Level</ToggleButton>
              </ToggleButtonGroup>

              {view === 'grade-level' && (
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="grade-select-label" size="small">Grade(s)</InputLabel>
                  <Select
                    labelId="grade-select-label"
                    id="grade-select"
                    multiple
                    value={selectedGrades}
                    label="Grade(s)"
                    onChange={handleGradeChange}
                    renderValue={(selected) => {
                      return [...selected]
                        .sort((a, b) => GRADES.indexOf(a) - GRADES.indexOf(b))
                        .map(grade => grade === 'Kindergarten' ? grade : `Grade ${grade}`)
                        .join(', ');
                    }}
                    size="small"
                  >
                    {availableGrades
                      .filter(grade => grade !== 'total')
                      .map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          {grade === 'Kindergarten' ? grade : `Grade ${grade}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <Tooltip title={isAllExpanded ? "Collapse all sections" : "Expand all sections"}>
                <IconButton 
                  onClick={isAllExpanded ? handleCollapseAll : handleExpandAll}
                  size="small"
                  sx={{ 
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                      color: 'primary.dark',
                    }
                  }}
                >
                  {isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}
                </IconButton>
              </Tooltip>
            </Box>

            {/* Missing Data Alert */}
            {!enrollmentData?.based_on_user_data && hasSkippedDataEntry && (
              <Alert 
                severity="info" 
                sx={{ mt: 2 }}
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => navigate('/data-entry')}
                  >
                    Enter Data
                  </Button>
                }
              >
                <AlertTitle>Missing Latest Enrollment Data</AlertTitle>
                Your projections are based on historical data only. For more accurate projections, please add your latest enrollment data.
              </Alert>
            )}
          </Container>
        </Box>
      )}
  
        {/* Loading and Error States */}
        {isLoading && !selectedSchool && (
          <CircularProgress />
        )}
  
        {error && (
          <Typography color="error">{error}</Typography>
        )}
  
        {/* Dialog */}
        {selectedSchool && !enrollmentData?.based_on_user_data && !hasSkippedDataEntry && (
          <Dialog
            open={openDataDialog}
            onClose={() => setOpenDataDialog(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              Add Latest Enrollment Data
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To ensure the most accurate enrollment projections, we recommend adding your latest enrollment data. 
                Would you like to enter this information now?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ p: 2, gap: 2 }}>
              <Button
                variant="outlined"
                onClick={handleSkipDataEntry}
                color="inherit"
              >
                Skip for Now
              </Button>
              <Button
                variant="contained"
                onClick={handleDataEntryRedirect}
                color="primary"
                autoFocus
              >
                Enter Enrollment Data
              </Button>
            </DialogActions>
          </Dialog>
        )}
  
        {selectedSchool && (
          <>
            {/* Scenarios Section */}
            <Accordion 
              expanded={expandedSections.scenarios}
              onChange={handleAccordionChange('scenarios')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <ShowChartIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Historical and Projected Enrollment Scenarios</Typography>
                    {!expandedSections.scenarios && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ml: 2,
                          color: 'text.secondary',
                          flexGrow: 1
                        }}
                      >
                        View and analyze enrollment projections based on historical trends
                      </Typography>
                    )}
                  </Box>
                  {expandedSections.scenarios && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        width: '100%',
                        mt: 1
                      }}
                    >
                      View and analyze enrollment projections based on historical trends
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  width: '100%',  // Ensure full width
                }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Enrollment Trends and Projections
                  </Typography>
                  <Paper 
                    sx={{ 
                      width: '100%',  // Take full width
                      overflow: 'hidden',  // Prevent double scrollbars
                      '& > *': {  // Apply to all direct children
                        width: '100%'
                      }
                    }}
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={400} />}>
                      {renderProjectionsContent()}
                    </Suspense>
                  </Paper>
                </Box>
              </AccordionDetails>
            </Accordion>
  
            {/* Risk Assessment Section */}
            <Accordion 
              expanded={expandedSections.risk}
              onChange={handleAccordionChange('risk')}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Assessment sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Risk Assessment</Typography>
                    {!expandedSections.risk && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ml: 2,
                          color: 'text.secondary',
                          flexGrow: 1
                        }}
                      >
                        Compare your network’s enrollment targets to projection scenarios based on historical data
                      </Typography>
                    )}
                  </Box>
                  {expandedSections.risk && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        width: '100%',
                        mt: 1
                      }}
                    >
                      Compare your network’s enrollment targets to projection scenarios based on historical data
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {renderRiskAssessment()}
              </AccordionDetails>
            </Accordion>
  
            {/* Impact Analysis Section */}
            <Accordion 
                expanded={expandedSections.impact}
                onChange={handleAccordionChange('impact')}
                >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <TrendingUp sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>Strategy Impact Analysis</Typography>
                        {!expandedSections.impact && (
                        <Typography 
                            variant="body2" 
                            sx={{ 
                            ml: 2,
                            color: 'text.secondary',
                            flexGrow: 1
                            }}
                        >
                            Model the impact of different recruitment and retention strategies
                        </Typography>
                        )}
                    </Box>
                    {expandedSections.impact && (
                        <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'text.secondary',
                            width: '100%',
                            mt: 1
                        }}
                        >
                        Model the impact of different recruitment and retention strategies
                        </Typography>
                    )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Suspense fallback={<CircularProgress />}>
                        <StrategyImpactAnalysis 
                          enrollmentData={enrollmentData}
                          onProjectionsUpdate={setAdjustedProjections}
                          timeframe={timeframe}
                          selectedGrades={selectedGrades}
                          view={view}
                          enrollmentTargets={enrollmentTargets}
                        />
                    </Suspense>
                    </AccordionDetails>
                </Accordion>
  
            {/* Now What Section */}
            <Accordion 
            expanded={expandedSections.actions}
            onChange={handleAccordionChange('actions')}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Explore sx={{ mr: 1, color: 'primary.main' }} />
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>Now What?</Typography>
                  {!expandedSections.actions && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ml: 2,
                        color: 'text.secondary',
                        flexGrow: 1
                      }}
                    >
                      Continue to explore with other enrollment planning tools
                    </Typography>
                  )}
                </Box>
                {expandedSections.actions && (
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'text.secondary',
                      width: '100%',
                      mt: 1
                    }}
                  >
                    Continue to explore with other enrollment planning tools
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => navigate('/geographic-explorer')}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      textAlign: 'left',
                      textTransform: 'none'
                    }}
                  >
                    <MapsHomeWork 
                      sx={{ 
                        fontSize: 40,
                        mb: 2,
                        color: 'primary.main'
                      }} 
                    />
                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                      Geographic Explorer
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Explore demographic and enrollment trends in your community
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => navigate('/marketshare-analyzer')}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      textAlign: 'left',
                      textTransform: 'none'
                    }}
                  >
                    <DonutSmallOutlined 
                      sx={{ 
                        fontSize: 40,
                        mb: 2,
                        color: 'primary.main'
                      }} 
                    />
                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                      Competitive Explorer
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Analyze market share data in your selected community
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => navigate('/data-reports')}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      textAlign: 'left',
                      textTransform: 'none'
                    }}
                  >
                    <DescriptionOutlined 
                      sx={{ 
                        fontSize: 40,
                        mb: 2,
                        color: 'primary.main'
                      }} 
                    />
                    <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                      Data Reports
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Download detailed reports and analysis for selected schools
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Container>
    </Box>
  </>
);
}

export default ProjectionsExplorer;