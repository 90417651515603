import React from 'react';
import {Container, Paper } from '@mui/material';
import EnrollmentEntry from './EnrollmentEntry';

const DataEntryPage = () => {
  const handleEnrollmentSubmit = (data) => {
    // Handle the submitted data here
    console.log('Submitted enrollment data:', data);
    // You might want to send this data to your backend or update state
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3 }}>
        <EnrollmentEntry onEnrollmentSubmit={handleEnrollmentSubmit} />
      </Paper>
    </Container>
  );
};

export default DataEntryPage;