import React from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container, 
  Grid, 
  Card, 
  CardContent, 
  Box 
} from '@mui/material';
import { Link } from 'react-router-dom';
import SiteFooter from '../SiteFooter'; 

function HomePage() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Navigation Bar */}
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <img src="/images/edc_logo_circle.png" alt="EDC Logo" style={{ height: '40px' }} />
          </Box>
          <Button color="inherit" component={Link} to="/about">About Us</Button>
          <Button color="inherit" component={Link} to="/login">Login</Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2" gutterBottom>
              Welcome to the Education Data Collective
            </Typography>
            <Typography variant="h5">
              Empowering educational decisions with data-driven insights
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Placeholder for future image */}
            <Box sx={{ height: 300, bgcolor: 'grey.300', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography>Image Coming Soon</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Services Section */}
      <Box sx={{ bgcolor: 'grey.100', py: 8 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" gutterBottom>
            Our Services
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Enrollment Prediction
                  </Typography>
                  <Typography>
                    Forecast future enrollment trends with our advanced predictive analytics.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Market Share Analysis
                  </Typography>
                  <Typography>
                    Understand your position in the educational landscape with comprehensive market share insights.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <SiteFooter />
    </Box>
  );
}

export default HomePage;