import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import customLogger from '../utils/customLogger';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    customLogger('Setting up auth state listener', 'info');
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        customLogger(`Auth state changed: User logged in (${user.email})`, 'info');
        verifyTokenWithBackend(user);
      } else {
        customLogger('Auth state changed: No user logged in', 'info');
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return () => {
      customLogger('Cleaning up auth state listener', 'info');
      unsubscribe();
    };
  }, []);

  const verifyTokenWithBackend = async (user) => {
    try {
      customLogger(`Verifying token with backend for user: ${user.email}`, 'info');
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_URL}/auth/verify-token`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to verify token with backend: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      customLogger(`Token verified successfully for user: ${user.email}`, 'info');
      setCurrentUser({ ...user, ...data });
      setLoading(false);
      return data.access_token; 
    } catch (error) {
      customLogger(`Error verifying token: ${error.message}`, 'error');
      setCurrentUser(null);
      setLoading(false);
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      customLogger(`Attempting login for user: ${email}`, 'info');
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      customLogger(`Firebase login successful for user: ${email}`, 'info');
      const access_token = await verifyTokenWithBackend(userCredential.user);
      return { access_token };
    } catch (error) {
      customLogger(`Login error for user ${email}: ${error.message}`, 'error');
      throw error;
    }
  };

  const logout = async () => {
    try {
      customLogger('Attempting to log out user', 'info');
      await signOut(auth);
      customLogger('Firebase sign out successful', 'info');
      
      const response = await fetch(`${API_URL}/api/auth/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      
      if (!response.ok) {
        customLogger(`Backend logout failed: ${response.status} ${response.statusText}`, 'warn');
      } else {
        customLogger('Backend logout successful', 'info');
      }
      
      setCurrentUser(null);
    } catch (error) {
      customLogger(`Logout error: ${error.message}`, 'error');
      throw error;
    }
  };

  const value = {
    currentUser,
    loading,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}