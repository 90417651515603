import React from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container, 
  Paper, 
  Box, 
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SiteFooter from '../SiteFooter';

function TermsOfService() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Navigation Bar */}
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <RouterLink to="/">
              <img src="/images/edc_logo_circle.png" alt="EDC Logo" style={{ height: '40px' }} />
            </RouterLink>
          </Box>
          <Button color="inherit" component={RouterLink} to="/">Home</Button>
          <Button color="inherit" component={RouterLink} to="/login">Login</Button>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="md" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Paper elevation={3} sx={{ mt: 8, mb: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white' }}>
        <Typography component="h1" variant="h4" gutterBottom align="center">
          EDUCATION DATA COLLECTIVE, LLC
        </Typography>
        <Typography component="h2" variant="h5" gutterBottom align="center">
          TERMS OF SERVICE
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
        <Typography component="span" sx={{ fontWeight: 'bold' }}>Last Updated</Typography>: September 28, 2024
        </Typography>
        <Box sx={{ mt: 2, width: '100%' }}>
          <Typography variant="body1" paragraph>
            The following terms and conditions ("<Typography component="span" sx={{ fontWeight: 'bold' }}>Terms</Typography>") govern all use by you ("<Typography component="span" sx={{ fontWeight: 'bold' }}>you</Typography>") of the Education Data Collective, LLC ("<Typography component="span" sx={{ fontWeight: 'bold' }}>EDC</Typography>," "<Typography component="span" sx={{ fontWeight: 'bold' }}>we</Typography>," "<Typography component="span" sx={{ fontWeight: 'bold' }}>us</Typography>," or "<Typography component="span" sx={{ fontWeight: 'bold' }}>our</Typography>") website located at <Link href="https://www.educationdatacollective.com/" target="_blank" rel="noopener">https://www.educationdatacollective.com/</Link> or any successor website (the "<Typography component="span" sx={{ fontWeight: 'bold' }}>Site</Typography>") and all content, services, and products available at or through the Site (taken together, the "<Typography component="span" sx={{ fontWeight: 'bold' }}>Services</Typography>"). The Services are offered subject to your acceptance without modification of all terms and conditions contained herein (including, without limitation, our Privacy Notice, incorporated herein by reference). You agree to the Terms on behalf of the organization, company or other legal entity for which you act ("<Typography component="span" sx={{ fontWeight: 'bold' }}>Your Organization</Typography>") and warrant that you have the authority to bind such entity in order to accept these Terms. 
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            1. Acceptance of the Terms of Use
          </Typography>
          <Typography variant="body1" paragraph>
            Please read these Terms carefully before you start to use the Services. <Typography component="span" sx={{ fontWeight: 'bold' }}>By using the Services or by clicking to accept or agree to these Terms when this option is made available to you, you accept and agree to be bound and abide by the Terms.</Typography> If you do not agree to all these Terms, you must not access or use the Services. 
          </Typography>
          <Typography variant="body1" paragraph>
            Our Services are not directed to children. The Services are offered and available to users who are 18 years of age or older and reside in the United States or any of its territories or possessions. By using the Services, you represent and warrant that you meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Services.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            2. Changes to the Terms of Service
          </Typography>
          <Typography variant="body1" paragraph>
            We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Services thereafter. Your continued use of the Services following the posting of revised Terms means that you accept and agree to the changes. However, any dispute that arose before the changes shall be governed by the Terms (including the binding individual arbitration clause) that were in place when the dispute arose.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
          3. Accessing the Services and Account Security
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to withdraw or modify the Services, and any service or material we provide through the Site, in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Services are unavailable at any time or for any period. From time to time, we may restrict user access, including registered user access, to some parts of the Services.
        </Typography>
        <Typography variant="body1" paragraph>
          You are responsible for both:
        </Typography>
        <Typography component="ul">
          <Typography component="li" variant="body1">
           Making all arrangements necessary for you to have access to the Services.
          </Typography>
          <Typography component="li" variant="body1" gutterBottom>
           Ensuring that all persons who access the Services through your internet connection and devices are aware of the Terms and comply with them.
          </Typography>
        </Typography>
        <Typography variant="body1" paragraph>
          To access some of the resources and services offered by the Site, you may be asked to provide certain registration details or other information. It is a condition of your use of the Services that all the information you provide to us is correct, current, and complete. You agree that all information you provide to register with the Services is governed by our Privacy Notice, and you consent to all actions we take with respect to your information consistent with our Privacy Notice.
        </Typography>
        <Typography variant="body1" paragraph>
          If you choose, or are provided with, a username, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Services or portions of it using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.
        </Typography>
        <Typography variant="body1" paragraph>
          We have the right to disable any username, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of the Terms.
        </Typography>

        <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
          4. Services Use
        </Typography>
        <Typography variant="body1" paragraph>
          We hereby grant you a limited, non-exclusive, non-transferable, revocable, worldwide right and license to access and use the Services, for the duration of the Terms, solely for internal organizational, non-commercial use and for no personal or other purposes. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Services, except as follows:
        </Typography>
        <Typography component="ul">
          <Typography component="li" variant="body1">
            Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
          </Typography>
          <Typography component="li" variant="body1">
            You may store files that are automatically cached by your Web browser for display enhancement purposes.
          </Typography>
          <Typography component="li" variant="body1" gutterBottom>
            You may print or download one copy of a reasonable number of pages of the Services for internal organizational, non-commercial use and not for further reproduction, publication, or distribution.
          </Typography>
        </Typography>
        <Typography variant="body1" paragraph>
          You must not:
        </Typography>
        <Typography component="ul">
          <Typography component="li" variant="body1">
            Modify copies of any materials from the Services.
          </Typography>
          <Typography component="li" variant="body1" gutterBottom>
            Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Services.
          </Typography>
        </Typography>
        <Typography variant="body1" paragraph>
          If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Services in breach of the Terms, your right to use the Services will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Services or any content on the Services is transferred to you, and all rights not expressly granted are reserved by EDC. Any use of the Services not expressly permitted by the Terms is a breach of the Terms and may violate copyright, trademark, and other laws.
        </Typography>
        <Typography variant="body1" paragraph>
          You may use the Services only for lawful purposes and in accordance with the Terms. You agree not to use the Services:
        </Typography>
        <Typography component="ul">
          <Typography component="li" variant="body1">
            In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries). 
          </Typography>
          <Typography component="li" variant="body1">
            For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
          </Typography>
          <Typography component="li" variant="body1">
            To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.
          </Typography>
          <Typography component="li" variant="body1">
            To impersonate or attempt to impersonate EDC, an EDC employee, another user, or any other person or entity (including, without limitation, by using email addresses associated with any of the foregoing).
          </Typography>
          <Typography component="li" variant="body1" gutterBottom>
            To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Services, or which, as determined by us, may harm EDC or users of the Services, or expose them to liability.
          </Typography>
        </Typography>
        <Typography variant="body1" paragraph>
          Additionally, you agree not to:
        </Typography>
        <Typography component="ul">
          <Typography component="li" variant="body1">
           Use the Services in any manner that could disable, overburden, damage, or impair the Site or interfere with any other party’s use of the Services, including their ability to engage in real time activities through the Services.
          </Typography>
          <Typography component="li" variant="body1">
           Use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services.
          </Typography>
          <Typography component="li" variant="body1">
           Use any manual process to monitor or copy any of the material on the Services, or for any other purpose not expressly authorized in the Terms, without our prior written consent.
          </Typography>
          <Typography component="li" variant="body1">
           Use any device, software, or routine that interferes with the proper working of the Services.
          </Typography>
          <Typography component="li" variant="body1">
           Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.
          </Typography>
          <Typography component="li" variant="body1">
           Transmit or store personal information as defined by applicable laws, including but not limited to the Family Educational Rights and Privacy Act, in or through the Services.
          </Typography>
          <Typography component="li" variant="body1">
           Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services are stored, or any server, computer, or database connected to the Services. 
          </Typography>
          <Typography component="li" variant="body1">
            Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.
          </Typography>
          <Typography component="li" variant="body1" gutterBottom>
          Otherwise attempt to interfere with the proper working of the Services.
          </Typography>
        </Typography>

        <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
          5. Monitoring and Enforcement; Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We have the right to:
        </Typography>
        <Typography component="ul">
          <Typography component="li" variant="body1">
            Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services. 
          </Typography>
          <Typography component="li" variant="body1" gutterBottom>
            Terminate or suspend your access to all or part of the Services for any or no reason, including without limitation, any violation of the Terms.
          </Typography>
        </Typography>
        <Typography variant="body1" paragraph>
          Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Services. YOU WAIVE AND HOLD HARMLESS EDC AND OUR AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </Typography>
        <Typography variant="body1" paragraph>
          However, we do not undertake to review material before it is posted on the Services and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section. 
        </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            6. Content
          </Typography>
          <Typography variant="body1" paragraph>
           You are solely responsible for the quality, accuracy and completeness of the information and data you transfer to us through your use of the Services (“<Typography component="span" sx={{ fontWeight: 'bold' }}>Content</Typography>”). Under no circumstances will we be liable in any way for the Content or any loss or damage of any kind incurred as a result of the use of any of the Content. 
          </Typography>
          <Typography variant="body1" paragraph>
           You hereby grant us a non-exclusive, perpetual, royalty-free, worldwide license to access, use, and distribute your Content for purposes of providing the Services, and agree and acknowledge that we may disclose your Content to Your Organization, Your Organization’s other users of the Services and the EDC customer paying for your use of the Services. In addition, you hereby grant us an irrevocable, non-exclusive, perpetual, royalty-free, worldwide license to reproduce, distribute, create derivative works, and otherwise use your Content in a de-identified, aggregated form (i.e. your Content is summarized together with our other users) to the extent and in the manner permitted under applicable law, including but not limited to the development and improvement of the Services. You represent, warrant and covenant that you have all necessary rights to convey any Content to us, and that the use of such Content by the Services does not infringe the rights (including intellectual property rights) of any third party. You agree and acknowledge that we have no obligation to retain any Content, and may delete any Content upon expiration or termination of the Terms.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            7. Intellectual Property Rights
          </Typography>
          <Typography variant="body1" paragraph>
          The Site and their entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by EDC, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property rights or proprietary rights laws. You do not acquire any ownership interest in the Services under the Terms or any other rights thereto, other than to use the Services, subject to all terms, conditions, and restrictions under the Terms. EDC and its licensors and service providers reserve and shall retain their entire right, title, and interest in and to the Services, including all intellectual property rights therein or relating thereto, except as expressly granted to you in the Terms.
          </Typography>
          <Typography variant="body1" paragraph>
          The EDC name and all related names, logos, product and service names, designs, and slogans are trademarks of EDC or its affiliates or licensors. You must not use such marks without the prior written permission of EDC. All other names, logos, product and service names, designs, and slogans on the Services are the trademarks of their respective owners.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            8. Reliance on Information Posted
          </Typography>
          <Typography variant="body1" paragraph>
           The information presented on or through the Services is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Services, or by anyone who may be informed of any of its contents.
          </Typography>
          <Typography variant="body1" paragraph>
           The Services may include, display or make available information or content provided by other users of the Services or third parties, including data, information, applications, and other products, services, and/or materials, or provide links to third-party websites or services, including through third-party advertising (“<Typography component="span" sx={{ fontWeight: 'bold' }}>Third-Party Materials</Typography>”). You acknowledge and agree that EDC is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. We do not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions. 
          </Typography>
          <Typography variant="body1" paragraph>
          If the Services contain links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to the Services, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            9. Changes and Updates to the Services
          </Typography>
          <Typography variant="body1" paragraph>
           We may update the content on the Services from time to time, but such content is not necessarily complete or up-to-date. Any of the material on the Services may be out of date at any given time, and we are under no obligation to update such material. 
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            10. Information About You 
          </Typography>
          <Typography variant="body1" paragraph>
           You acknowledge that when you use the Services, we may use automatic means (including, for example, cookies and web beacons) to collect information about your use of the Services. All information we collect through the Services is subject to our Privacy Notice. By using the Services, you consent to all actions taken by us with respect to your information in compliance with the Privacy Notice. 
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            11. Use of AI Technology
          </Typography>
          <Typography variant="body1" paragraph>
           Certain Services provided by EDC may be facilitated or powered by third-party artificial intelligence technologies (collectively, “<Typography component="span" sx={{ fontWeight: 'bold' }}>AI Technology</Typography>”). While we use commercially reasonable means to reduce errors and omissions arising from use of AI Technology, we make no warranties, express or implied, regarding the accuracy, reliability or non-infringement of our use of AI Technology and disclaim any liability for errors, actions or omissions taken by you based on any AI Technology. In addition, in certain jurisdictions, notice and/or consent may be required from the downstream individual in the event AI Technology is used in connection with the processing of “personal information”, “personal data” or otherwise. In the event that any consents or notices are required, you agree and acknowledge that you are solely responsible for providing all required consents or notices with respect to the Services (including use of AI Technology).
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            12. Geographic Restrictions
          </Typography>
          <Typography variant="body1" paragraph>
           The Services are provided for access and use only by persons located in the United States. You acknowledge that you may not be able to access all or some of the Services outside of the United States and that access thereto may not be legal by certain persons or in certain countries. If you access the Services from outside the United States, you are responsible for compliance with local laws.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            13. Termination
          </Typography>
          <Typography variant="body1" paragraph>
           The Terms become effective when you access the Services or acknowledge your acceptance by clicking to accept or agree to the Terms, and will continue in effect until terminated by you or us. We may terminate your access to all or any part of the Services at any time, with or without cause, with or without notice, effective immediately. You may discontinue your use of the Services at any time. If you wish to terminate your account (if you have one), please send an email to support@educationdatacollective.com. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            14. Disclaimer of Warranties
          </Typography>
          <Typography variant="body1" paragraph>
           You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Services will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to the Services for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES.
          </Typography>
          <Typography variant="body1" paragraph>
           YOUR USE OF THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER EDC NOR ANY PERSON ASSOCIATED WITH EDC MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER EDC NOR ANYONE ASSOCIATED WITH EDC REPRESENTS OR WARRANTS THAT THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. 
          </Typography>
          <Typography variant="body1" paragraph>
            TO THE FULLEST EXTENT PROVIDED BY LAW, EDC, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
          </Typography>
          <Typography variant="body1" paragraph>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            15. Limitation on Liability
          </Typography>
          <Typography variant="body1" paragraph>
           TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE LIABILITY OF EDC AND ITS AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE AMOUNT YOU HAVE PAID TO EDC FOR THE SERVICES.
          </Typography>
          <Typography variant="body1" paragraph>
           THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            16. Indemnification
          </Typography>
          <Typography variant="body1" paragraph>
           You agree to defend, indemnify, and hold harmless EDC, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of the Terms or your use of the Services, including, but not limited to, any use of the content, services, and products other than as expressly authorized in the Terms, or your use of any information obtained from the Services.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            17. Governing Law and Jurisdiction
          </Typography>
          <Typography variant="body1" paragraph>
            All matters relating to the Services or the Terms, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Colorado without giving effect to any choice or conflict of law provision or rule (whether of Colorado or any other jurisdiction). Any legal suit, action, or proceeding arising out of or related to the Services or the Terms will be instituted exclusively in the federal courts of the United States or the courts of the State of Colorado in each case located in the city and County of Denver, and each Party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding.
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            18. Waiver and Severability
          </Typography>
          <Typography variant="body1" paragraph>
           No waiver by EDC of any term or condition set out in the Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of EDC to assert a right or provision under the Terms shall not constitute a waiver of such right or provision.
          </Typography>
          <Typography variant="body1" paragraph>
           If any provision of the Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect. 
          </Typography>

          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            19. Entire Agreement
          </Typography>
          <Typography variant="body1" paragraph>
           The Terms constitute the sole and entire agreement between you and EDC regarding the Services and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Services. 
          </Typography>
          
          <Typography component="h3" variant="h6" gutterBottom fontWeight="bold">
            20. Your Comments and Concerns
          </Typography>
          <Typography variant="body1" paragraph>
            Please feel free to contact us if you have any feedback, comments, requests for technical support, or other communications relating to the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            You may contact us as follows:
          </Typography>
          <Typography variant="body1" component="address">
          <Typography component="span" sx={{ fontWeight: 'bold' }}>Education Data Collective, LLC</Typography><br />
            PO Box 7062<br />
            Denver, CO 80207<br />
            Email: <Link href="mailto:support@educationdatacollective.com">support@educationdatacollective.com</Link>
          </Typography>
        </Box>
      </Paper>
    </Container>

    {/* Footer */}
    <SiteFooter />
    </Box>
  );
}

export default TermsOfService;