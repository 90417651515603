/**
 * Deduplicate schools array by ncessch
 */
export const deduplicateSchools = (schools) => {
  if (!schools?.length) return [];
  return Array.from(new Map(schools.map(school => [school.ncessch, school])).values());
};

/**
 * Format market share percentage consistently
 */
export const formatMarketShare = (value, decimals = 1) => {
  if (value === null || value === undefined) return '0';
  return (value * 100).toFixed(decimals);
};

/**
 * Standardizes grade keys for consistent analysis
 * Returns null for Pre-K grades and standardizes other grade names
 */
export const standardizeGradeKey = (grade) => {
  if (!grade) return null;
  const normalizedGrade = grade.toString().toLowerCase().trim();
  
  // Pre-K variations
  if (normalizedGrade.includes('pre-k') || 
      normalizedGrade.includes('pre k') || 
      normalizedGrade.includes('pk') ||
      normalizedGrade === 'pre' ||
      normalizedGrade.includes('prekindergarten')) {
    return null;
  }
  
  // Grade prefix variations
  const withoutPrefix = normalizedGrade
    .replace('grades ', '')
    .replace('grade ', '');
  
  // Kindergarten variations
  if (withoutPrefix === 'k' || 
      withoutPrefix === 'kg' || 
      withoutPrefix === '0' || 
      withoutPrefix.includes('kindergarten')) {
    return 'Kindergarten';
  }
  
  // Handle numeric grades
  const numericGrade = parseInt(withoutPrefix.replace(/\D/g, ''));
  if (!isNaN(numericGrade) && numericGrade >= 1 && numericGrade <= 12) {
    return `Grade ${numericGrade}`;
  }
  
  return null;
};


/**
 * Gets validated grade range for a school, excluding Pre-K
 */
export const getSchoolGradeRange = (gradeRangeStr) => {
  if (!gradeRangeStr) return [];
  
  // Split the range and clean it up
  const gradeParts = gradeRangeStr.toLowerCase().split('-');
  const startGrade = gradeParts[0].trim();
  const endGrade = gradeParts[gradeParts.length - 1].trim();
  
  // If it starts with Pre-K, use Kindergarten as the effective start
  const isPreKStart = startGrade.includes('pre-k') || 
                     startGrade.includes('pre k') || 
                     startGrade.includes('pk') ||
                     startGrade.includes('prekindergarten');
  
  const effectiveStartGrade = isPreKStart ? 'kindergarten' : startGrade;
  const effectiveEndGrade = endGrade.trim();
  
  // Convert to standardized format
  const start = standardizeGradeKey(effectiveStartGrade);
  const end = standardizeGradeKey(effectiveEndGrade);
  
  // Debug standardized grades
  console.log('Standardized grade range:', {
    original: gradeRangeStr,
    isPreKStart,
    effectiveStartGrade,
    effectiveEndGrade,
    start,
    end
  });
  
  if (!end) return [];  // Only require valid end grade
  
  const gradeOrder = ['Kindergarten', ...Array.from({length: 12}, (_, i) => `Grade ${i + 1}`)];
  const startIdx = Math.max(0, gradeOrder.indexOf(start)); // Default to Kindergarten if start is invalid
  const endIdx = gradeOrder.indexOf(end);
  
  if (endIdx === -1) return [];
  
  const [lower, upper] = startIdx <= endIdx ? [startIdx, endIdx] : [endIdx, startIdx];
  const result = gradeOrder.slice(lower, upper + 1);
  
  // Debug final result
  console.log('Final grade range:', result);
  
  return result;
};

/**
 * Check if a grade is within a school's grade range
 */
export const isGradeInRange = (selectedGrade, schoolGradeRange) => {
  const standardizedGrade = standardizeGradeKey(selectedGrade);
  if (!standardizedGrade || !schoolGradeRange.length) return false;
  return schoolGradeRange.includes(standardizedGrade);
};

/**
 * Filters schools based on all criteria
 */
export const filterSchools = (schools, filters, schoolsEnrollmentData = []) => {
  const {
    selectedGrades = [],
    governanceFilter = 'All',
    schoolTypeFilter = 'All'
  } = filters;

  // Early return if no schools or grades
  if (!schools || !selectedGrades || selectedGrades.length === 0) return [];

  // Debug initial state
  console.log('Filter Input:', {
    totalSchools: schools.length,
    schoolsWithEnrollmentData: schoolsEnrollmentData.length,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter
  });

  return schools.filter(school => {
    // Skip schools with invalid data
    if (!school || !school.grade_range) {
      console.log('Skipping school - invalid data:', school?.name);
      return false;
    }

    // Get school's grade range (now includes schools starting with Pre-K)
    const schoolGradeRange = getSchoolGradeRange(school.grade_range);
    if (schoolGradeRange.length === 0) {
      console.log('Skipping school - no valid grade range:', school.name, school.grade_range);
      return false;
    }

    // Check if any selected grade is in the school's range
    const hasMatchingGrades = selectedGrades.some(grade => 
      isGradeInRange(grade, schoolGradeRange)
    );
    if (!hasMatchingGrades) {
      console.log('Skipping school - no matching grades:', school.name, schoolGradeRange);
      return false;
    }

    // Governance filter
    if (governanceFilter !== 'All') {
      const isCharter = school.charter === 'Yes';
      if ((governanceFilter === 'Charter' && !isCharter) || 
          (governanceFilter === 'District' && isCharter)) {
        console.log('Skipping school - governance mismatch:', school.name, school.charter);
        return false;
      }
    }

    // School type filter
    if (schoolTypeFilter !== 'All') {
      // Find enrollment data for this school
      const enrollmentData = schoolsEnrollmentData.find(s => s.ncessch === school.ncessch);
      const schoolType = enrollmentData?.school_type || school.school_type;
      
      if (!schoolType || schoolType !== schoolTypeFilter) {
        console.log('Skipping school - type mismatch:', school.name, schoolType);
        return false;
      }
    }

    return true;
  });
};


/**
 * Calculates enrollment for specific grades, excluding Pre-K
 */
export const calculateEnrollment = (enrollmentByGrade, selectedGrades) => {
  if (!enrollmentByGrade || !selectedGrades || selectedGrades.length === 0) return 0;

  const standardizedGrades = selectedGrades.map(grade => standardizeGradeKey(grade)).filter(Boolean);
  
  return standardizedGrades.reduce((sum, grade) => {
    return sum + (enrollmentByGrade[grade] || 0);
  }, 0);
};

/**
 * Calculates market shares for schools
 */
export const calculateMarketShares = (filteredSchools, schoolsEnrollmentData, selectedGrades) => {
  if (!filteredSchools || !schoolsEnrollmentData || !selectedGrades || 
      filteredSchools.length === 0 || schoolsEnrollmentData.length === 0 || selectedGrades.length === 0) {
    return {};
  }

  const uniqueSchools = deduplicateSchools(filteredSchools);
  const standardizedGrades = selectedGrades
    .map(grade => standardizeGradeKey(grade))
    .filter(Boolean);

  const years = ['2017', '2022'];
  const marketShares = {};

  years.forEach(year => {
    const yearKey = year === '2017' ? 'five_years_ago' : 'current';
    
    const totalEnrollment = uniqueSchools.reduce((sum, school) => {
      const schoolData = schoolsEnrollmentData.find(s => s.ncessch === school.ncessch);
      if (!schoolData?.enrollment_by_grade?.[yearKey]) return sum;
      
      return sum + calculateEnrollment(schoolData.enrollment_by_grade[yearKey], standardizedGrades);
    }, 0);

    uniqueSchools.forEach(school => {
      const schoolData = schoolsEnrollmentData.find(s => s.ncessch === school.ncessch);
      if (!schoolData?.enrollment_by_grade?.[yearKey]) {
        marketShares[school.ncessch] = marketShares[school.ncessch] || {};
        marketShares[school.ncessch][year] = 0;
        return;
      }

      const schoolEnrollment = calculateEnrollment(
        schoolData.enrollment_by_grade[yearKey], 
        standardizedGrades
      );

      marketShares[school.ncessch] = marketShares[school.ncessch] || {};
      marketShares[school.ncessch][year] = totalEnrollment > 0 ? schoolEnrollment / totalEnrollment : 0;
    });
  });

  uniqueSchools.forEach(school => {
    if (marketShares[school.ncessch]) {
      marketShares[school.ncessch].change = 
        (marketShares[school.ncessch]['2022'] || 0) - 
        (marketShares[school.ncessch]['2017'] || 0);
    }
  });

  return marketShares;
};

/**
 * Availble School Types (e.g., Regular School)
 */
export const getAvailableSchoolTypes = (schoolsEnrollmentData) => {
  if (!schoolsEnrollmentData?.length) return ['All'];
  
  const types = new Set(['All']);
  schoolsEnrollmentData.forEach(school => {
    if (school.school_type) {
      types.add(school.school_type.trim());
    }
  });
  
  return Array.from(types).sort();
};

// Simple debug logging function
export const logMarketShareData = (schoolId, filteredSchools, schoolsEnrollmentData, selectedGrades) => {
  console.group('Market Share Debug');
  
  console.log('Input Data:', {
    schoolId,
    filteredSchoolsCount: filteredSchools?.length,
    schoolsEnrollmentDataCount: schoolsEnrollmentData?.length,
    selectedGrades,
    schoolNames: filteredSchools?.map(s => s.name)
  });

  const standardizedGrades = selectedGrades
    .map(grade => standardizeGradeKey(grade))
    .filter(Boolean);
  
  console.log('Standardized Grades:', standardizedGrades);

  const marketShares = calculateMarketShares(filteredSchools, schoolsEnrollmentData, selectedGrades);
  console.log('Market Shares:', marketShares[schoolId]);

  console.groupEnd();
};

// Validation utility
export const validateSchools = (schools, filters) => {
  console.group('Schools Validation');
  console.log('Schools:', schools?.length);
  console.log('Filters:', filters);
  console.groupEnd();
  return filterSchools(schools, filters);
};

// Debug helper
export const debugGradeRange = (gradeRangeStr) => {
  console.group('Grade Range Debug');
  console.log('Input:', gradeRangeStr);
  
  const [startGrade, endGrade] = gradeRangeStr
    .toLowerCase()
    .replace('pre-k-', '')
    .replace('pk-', '')
    .split('-')
    .map(g => g.trim());
  
  console.log('Split grades:', { startGrade, endGrade });
  
  const start = standardizeGradeKey(startGrade);
  const end = standardizeGradeKey(endGrade);
  console.log('Standardized grades:', { start, end });
  
  const result = getSchoolGradeRange(gradeRangeStr);
  console.log('Final grade range:', result);
  
  console.groupEnd();
  return result;
};