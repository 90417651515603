// src/utils/customLogger.js

const customLogger = (message, level = 'log') => {
    if (process.env.NODE_ENV !== 'production') {
      switch (level) {
        case 'error':
          console.error(message);
          break;
        case 'warn':
          console.warn(message);
          break;
        case 'info':
          console.info(message);
          break;
        default:
          console.log(message);
      }
    }
    // In production, you might want to send important logs to a logging service
    // if (process.env.NODE_ENV === 'production' && level === 'error') {
    //   // Send to logging service
    // }
  };
  
  export default customLogger;