import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function SiteFooter() {
  return (
    <Box component="footer" sx={{ bgcolor: 'grey.200', py: 4, mt: 'auto' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <img src="/images/edc_logo.png" alt="EDC Logo" style={{ maxWidth: '100%', height: 'auto', maxHeight: '200px' }} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" gutterBottom>
                  Location
                </Typography>
                <Typography variant="body2">
                  PO Box 7062
                  <br />
                  Denver, CO 80207
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" gutterBottom>
                  Contact
                </Typography>
                <Typography variant="body2">
                  <Link href="mailto:info@datacollective.com" color="inherit">
                    info@datacollective.com
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" gutterBottom>
                  Legal
                </Typography>
                <Typography variant="body2">
                  <Link component={RouterLink} to="/terms-of-service" color="inherit">
                    Terms of Service
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SiteFooter;