import React, { useState, useEffect, useCallback, Suspense, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Typography,
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Alert,
  Skeleton,
  Paper,
  CircularProgress,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  School as SchoolOutlined,
  ExpandMore,
  FilterList,
  Map as MapIcon,
  ShowChart as ShowChartIcon,
  BarChart as BarChartIcon,
  MapsHomeWork,
  AutoGraphOutlined,
  DescriptionOutlined,
  Explore,
  UnfoldLess,
  UnfoldMore
} from '@mui/icons-material';
import { formatSchoolName, capitalizeWord } from '../../utils/nameFormatter';
import { apiFetch } from '../../utils/api';
import { filterSchools, getAvailableSchoolTypes } from '../../utils/marketShareUtils';

// Lazy load components
const AttendMarketShareCard = React.lazy(() => import('./AttendMarketShareCard'));
const ResideMarketShareCard = React.lazy(() => import('./ResideMarketShareCard'));
const MarketShareMap = React.lazy(() => import('./MarketShareMap'));
const EnrollmentComparisonChart = React.lazy(() => import('./EnrollmentComparisonChart'));

const GRADES = ['Kindergarten', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
const DRIVE_TIMES = [5, 10, 15];

function MarketShareAnalyzer() {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [esriData, setEsriData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [esriLoading, setEsriLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [schoolsEnrollmentData, setSchoolsEnrollmentData] = useState([]);
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [enrollmentProjections, setEnrollmentProjections] = useState(null);
  const [nearbySchools, setNearbySchools] = useState({});
  const [nearbySchoolsLoading, setNearbySchoolsLoading] = useState(false);
  const [schoolsLoading, setSchoolsLoading] = useState(true);
  const [visibleDriveTimes, setVisibleDriveTimes] = useState([]);
  const [schoolTypeFilter, setSchoolTypeFilter] = useState('All');
  const [availableSchoolTypes, setAvailableSchoolTypes] = useState([]);
  const enrollmentDataIdsRef = useRef(new Set());
  const [expandedSections, setExpandedSections] = useState({
    filters: true,
    marketShare: false,
    map: false,
    enrollment: false,
    actions: false
  });

  const handleExpandAll = () => {
    const allExpanded = Object.fromEntries(
      Object.keys(expandedSections).map(key => [key, true])
    );
    setExpandedSections(allExpanded);
  };

  const handleCollapseAll = () => {
    const allCollapsed = Object.fromEntries(
      Object.keys(expandedSections).map(key => [key, false])
    );
    setExpandedSections(allCollapsed);
  };

  const isAllExpanded = Object.values(expandedSections).every(value => value === true);

  const fetchSchools = useCallback(async () => {
    setSchoolsLoading(true);
    try {
      const data = await apiFetch(`/schools`);
      const uniqueSchools = Array.from(new Map(data.map(school => [school.ncessch, school])).values())
        .sort((a, b) => formatSchoolName(a.name).localeCompare(formatSchoolName(b.name)));

      setSchools(uniqueSchools);
    } catch (error) {
      console.error('Error fetching school data:', error);
      setError('Failed to load school data. Please try again later.');
      if (error.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    } finally {
      setSchoolsLoading(false);
      setLoading(false);
    }
  }, [navigate]);

  const fetchMarketShareData = useCallback(async (schoolId) => {
    setEsriLoading(true);
    try {
      const data = await apiFetch(`/school/${schoolId}/esri-market-share`);
      setEsriData(data);
    } catch (error) {
      console.error('Error fetching ESRI market share data:', error);
      setError('Failed to load ESRI market share data. Please try again later.');
      if (error.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    } finally {
      setEsriLoading(false);
    }
  }, [navigate]);

  const fetchEnrollmentProjections = useCallback(async (schoolId) => {
    try {
      const data = await apiFetch(`/school/${schoolId}/enrollment-projections`);
      setEnrollmentProjections(data);
    } catch (error) {
      console.error('Error fetching enrollment projections:', error);
      setError('Failed to load enrollment projections. Please try again later.');
    }
  }, []);

  const fetchNearbySchools = useCallback(async (schoolId) => {
    setNearbySchoolsLoading(true);
    try {
      const allNearbySchools = {};
      for (const driveTime of DRIVE_TIMES) {
        const data = await apiFetch(`/school/${schoolId}/nearby-schools/${driveTime}`);
        allNearbySchools[driveTime] = data;
      }
      setNearbySchools(allNearbySchools);
    } catch (error) {
      console.error('Error fetching nearby schools data:', error);
      setError('Failed to load nearby schools data. Please try again later.');
    } finally {
      setNearbySchoolsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSchools();
  }, [fetchSchools]);

  useEffect(() => {
    if (selectedSchool) {
      fetchMarketShareData(selectedSchool.ncessch);
      fetchEnrollmentProjections(selectedSchool.ncessch);
      fetchNearbySchools(selectedSchool.ncessch);
    }
  }, [selectedSchool, fetchMarketShareData, fetchEnrollmentProjections, fetchNearbySchools]);

  // Update to extract school types from enrollment data
  useEffect(() => {
    if (schoolsEnrollmentData.length > 0) {
      const availableTypes = getAvailableSchoolTypes(schoolsEnrollmentData);
      setAvailableSchoolTypes(availableTypes);
      
      // Reset school type filter if current value is invalid
      if (!availableTypes.includes(schoolTypeFilter)) {
        setSchoolTypeFilter('All');
      }
    }
  }, [schoolsEnrollmentData, schoolTypeFilter]);

  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !nearbySchools || !nearbySchools[selectedDriveTime] || selectedGrades.length === 0) {
      return [];
    }
  
    // Get schools for the selected drive time
    const schoolsForSelectedDriveTime = nearbySchools[selectedDriveTime] || [];
    
    // Merge school data with enrollment data to ensure types are preserved
    const schoolsWithTypes = [selectedSchool, ...schoolsForSelectedDriveTime].map(school => {
      const enrollmentData = schoolsEnrollmentData.find(s => s.ncessch === school.ncessch);
      return {
        ...school,
        school_type: enrollmentData?.school_type || school.school_type
      };
    });
  
    return filterSchools(schoolsWithTypes, {
      selectedGrades,
      governanceFilter,
      schoolTypeFilter
    }, schoolsEnrollmentData);
  }, [selectedSchool, nearbySchools, selectedDriveTime, selectedGrades, governanceFilter, schoolTypeFilter, schoolsEnrollmentData]);


  const fetchMissingSchoolsData = useCallback(async (ncesschList) => {
    try {
      // Use ref instead of recalculating from schoolsEnrollmentData
      const missingSchools = ncesschList.filter(
        ncessch => !enrollmentDataIdsRef.current.has(ncessch)
      );
      
      if (missingSchools.length > 0) {
        const data = await apiFetch('/schools-enrollment-by-grade', {
          method: 'POST',
          body: JSON.stringify({ ncesschList: missingSchools }),
        });
        
        setSchoolsEnrollmentData(prevData => {
          const existingSchools = new Map(prevData.map(school => [school.ncessch, school]));
          
          // Update both state and ref
          data.forEach(school => {
            if (!existingSchools.has(school.ncessch)) {
              existingSchools.set(school.ncessch, school);
              enrollmentDataIdsRef.current.add(school.ncessch);
            }
          });
          
          return Array.from(existingSchools.values());
        });
      }
    } catch (error) {
      console.error('Error fetching schools enrollment data:', error);
      setError('Failed to load enrollment data. Please try again later.');
    }
  }, []);

  useEffect(() => {
    if (selectedSchool && nearbySchools && Object.keys(nearbySchools).length > 0) {
      const allSchools = [selectedSchool, ...Object.values(nearbySchools).flat()];
      const uniqueSchools = Array.from(new Set(allSchools.map(s => s.ncessch))).map(
        ncessch => allSchools.find(s => s.ncessch === ncessch)
      );
      const ncesschList = uniqueSchools.map(school => school.ncessch);
      
      fetchMissingSchoolsData(ncesschList);
    }
  }, [selectedSchool, nearbySchools, fetchMissingSchoolsData]);

  useEffect(() => {
    enrollmentDataIdsRef.current = new Set(schoolsEnrollmentData.map(s => s.ncessch));
  }, [schoolsEnrollmentData]);

  useEffect(() => {
    if (selectedSchool && selectedSchool.grades) {
      const schoolGrades = selectedSchool.grades
        .map(grade => grade.replace('Grade ', '').trim() === '0' ? 'Kindergarten' : grade.replace('Grade ', '').trim())
        .filter(grade => grade !== 'Pre-Kindergarten' && grade !== 'PK');
      
      setSelectedGrades(schoolGrades);
    } else {
      setSelectedGrades([]);
    }
  }, [selectedSchool]);

  useEffect(() => {
    setVisibleDriveTimes([selectedDriveTime]);
  }, [selectedDriveTime]);

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      setSelectedSchool({...school, name: formatSchoolName(school.name)});
      setEsriData(null);
    }
  };

  const handleGovernanceChange = (event) => {
    setGovernanceFilter(event.target.value);
  };

  const handleDriveTimeChange = (event) => {
    setSelectedDriveTime(Number(event.target.value));
  };

  const handleGradeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  };

  const handleAccordionChange = (section) => (event, isExpanded) => {
    setExpandedSections({
      ...expandedSections,
      [section]: isExpanded
    });
  };

  const formatAddress = (school) => {
    const formattedStreetAddress = school.street_address
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    const formattedCity = school.city
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    return `${formattedStreetAddress}\n${formattedCity}, ${school.state} ${school.zip_code}`;
  };

  const getActiveFiltersString = () => {
    const filters = [];
    if (schoolTypeFilter !== 'All') filters.push(`Type: ${schoolTypeFilter}`);
    if (governanceFilter !== 'All') filters.push(`Governance: ${governanceFilter}`);
    if (selectedGrades.length > 0) filters.push(`Grades: ${selectedGrades.join(', ')}`);
    if (selectedDriveTime) filters.push(`${selectedDriveTime} minutes`);
    return filters.length > 0 ? filters.join(' • ') : 'No filters applied';
  };

  // Render loading skeleton
  const renderSkeleton = () => (
    <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
      {[1, 2].map((item) => (
        <Grid item xs={12} md={6} key={item}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
      ))}
    </Grid>
  );

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
          Competitive Explorer
        </Typography>
        <Skeleton variant="rectangular" height={56} sx={{ mb: 4 }} />
        {renderSkeleton()}
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Competitive Explorer
        </Typography>
        {selectedSchool && (
          <Tooltip title={isAllExpanded ? "Collapse all sections" : "Expand all sections"}>
            <IconButton 
              onClick={isAllExpanded ? handleCollapseAll : handleExpandAll}
              size="large"
              sx={{ 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: 'primary.dark',
                }
              }}
            >
              {isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
      
      <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
        {selectedSchool
          ? "Analyze market share data for the selected school. Use the filters to refine your analysis."
          : "Use this tool to analyze market share by students who reside and attend schools in your area. Start by selecting a school."}
      </Typography>
      
      {!selectedSchool && (
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel id="school-select-label">Select a School</InputLabel>
          <Select
            labelId="school-select-label"
            id="school-select"
            value={selectedSchool ? selectedSchool.ncessch : ''}
            label="Select a School"
            onChange={handleSchoolChange}
            disabled={schoolsLoading}
          >
            {schools.map((school) => (
              <MenuItem key={school.ncessch} value={school.ncessch}>
                {formatSchoolName(school.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
  
      {selectedSchool && (
        <>
          <Accordion 
            expanded={expandedSections.filters}
            onChange={handleAccordionChange('filters')}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="filters-content"
              id="filters-header"
              sx={{
                '& .MuiAccordionSummary-content': {
                  flexDirection: 'column',
                  margin: '12px 0',
                }
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                width: '100%',
                mb: expandedSections.filters ? 0 : 1
              }}>
                <FilterList 
                  sx={{ 
                    mr: 1,
                    color: 'primary.main'
                  }} 
                />
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 600,
                    color: 'text.primary'
                  }}
                >
                  Filters
                </Typography>
              </Box>
              {!expandedSections.filters && (
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                    ml: 4
                  }}
                >
                  {getActiveFiltersString()}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <Box sx={{ p: 2 }}>
                {/* School Information Card */}
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 2, 
                    mb: 3, 
                    backgroundColor: 'grey.50',
                    borderRadius: 2
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <SchoolOutlined 
                      sx={{ 
                        mr: 1,
                        color: 'primary.main'
                      }} 
                    />
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 500,
                        color: 'text.primary'
                      }}
                    >
                      {formatSchoolName(selectedSchool.name)}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {formatAddress(selectedSchool)}
                  </Typography>
                </Paper>
  
                {/* Filters Grid */}
                <Grid container spacing={3}>
                  {/* First Row */}
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth size="medium">
                      <InputLabel id="school-select-label">School</InputLabel>
                      <Select
                        labelId="school-select-label"
                        id="school-select"
                        value={selectedSchool.ncessch}
                        label="School"
                        onChange={handleSchoolChange}
                        disabled={schoolsLoading}
                        sx={{ minHeight: 56 }}
                      >
                        {schools.map((school) => (
                          <MenuItem key={school.ncessch} value={school.ncessch}>
                            {formatSchoolName(school.name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
  
                  <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="medium">
                    <InputLabel id="school-type-select-label">School Type</InputLabel>
                    <Select
                      labelId="school-type-select-label"
                      id="school-type-select"
                      value={schoolTypeFilter}
                      label="School Type"
                      onChange={(e) => setSchoolTypeFilter(e.target.value)}
                      sx={{ minHeight: 56 }}
                    >
                      {availableSchoolTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
  
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth size="medium">
                      <InputLabel id="governance-select-label">Governance</InputLabel>
                      <Select
                        labelId="governance-select-label"
                        id="governance-select"
                        value={governanceFilter}
                        label="Governance"
                        onChange={handleGovernanceChange}
                        sx={{ minHeight: 56 }}
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Charter">Charter</MenuItem>
                        <MenuItem value="District">District</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
  
                  {/* Second Row */}
                  <Grid item xs={12} md={8}>
                    <FormControl fullWidth size="medium">
                      <InputLabel id="grade-select-label">Grades</InputLabel>
                      <Select
                        labelId="grade-select-label"
                        id="grade-select"
                        multiple
                        value={selectedGrades}
                        onChange={handleGradeChange}
                        input={<OutlinedInput label="Grades" />}
                        renderValue={(selected) => selected.join(', ')}
                        sx={{ minHeight: 56 }}
                      >
                        {GRADES.map((grade) => (
                          <MenuItem key={grade} value={grade}>
                            <Checkbox checked={selectedGrades.indexOf(grade) > -1} />
                            <ListItemText primary={grade} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
  
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth size="medium">
                      <InputLabel id="drive-time-select-label">Drive Time</InputLabel>
                      <Select
                        labelId="drive-time-select-label"
                        id="drive-time-select"
                        value={selectedDriveTime}
                        label="Drive Time"
                        onChange={handleDriveTimeChange}
                        sx={{ minHeight: 56 }}
                      >
                        {DRIVE_TIMES.map((time) => (
                          <MenuItem key={time} value={time}>
                            {time} minutes
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
  
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          ) : esriLoading || nearbySchoolsLoading ? (
            renderSkeleton()
          ) : selectedSchool && esriData ? (
            <>
              <Accordion 
                expanded={expandedSections.marketShare}
                onChange={handleAccordionChange('marketShare')}
                defaultExpanded
                sx={{ mt: 2 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="market-share-content"
                  id="market-share-header"
                  sx={{
                    '& .MuiAccordionSummary-content': {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    width: '100%',
                    mb: expandedSections.marketShare ? 1 : 0
                  }}>
                    <ShowChartIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Headlines</Typography>
                    {!expandedSections.marketShare && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ml: 2,
                          color: 'text.secondary',
                          flexGrow: 1
                        }}
                      >
                        Market share analysis based on population and enrollment trends
                      </Typography>
                    )}
                  </Box>
                  {expandedSections.marketShare && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        ml: 4
                      }}
                    >
                      Market share analysis based on population and enrollment trends
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                        <AttendMarketShareCard
                          selectedSchool={selectedSchool}
                          filteredSchools={filteredSchools}
                          selectedDriveTime={selectedDriveTime}
                          schoolsEnrollmentData={schoolsEnrollmentData}
                          governanceFilter={governanceFilter}
                          selectedGrades={selectedGrades}
                          schoolTypeFilter={schoolTypeFilter}
                        />
                      </Suspense>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                        <ResideMarketShareCard
                          selectedSchool={selectedSchool}
                          filteredSchools={filteredSchools}
                          marketShareData={esriData}
                          selectedDriveTime={selectedDriveTime}
                          selectedGrades={selectedGrades}
                          enrollmentProjections={enrollmentProjections}
                          schoolsEnrollmentData={schoolsEnrollmentData}
                          governanceFilter={governanceFilter}
                        />
                      </Suspense>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
  
              <Accordion 
                expanded={expandedSections.map}
                onChange={handleAccordionChange('map')}
                defaultExpanded
                sx={{ mt: 2 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="map-content"
                  id="map-header"
                  sx={{
                    '& .MuiAccordionSummary-content': {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    width: '100%',
                    mb: expandedSections.map ? 1 : 0
                  }}>
                    <MapIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Community Map</Typography>
                    {!expandedSections.map && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ml: 2,
                          color: 'text.secondary',
                          flexGrow: 1
                        }}
                      >
                        School-level market share analysis within selected drive times
                      </Typography>
                    )}
                  </Box>
                  {expandedSections.map && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        ml: 4
                      }}
                    >
                      School-level market share analysis within selected drive times
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Paper elevation={3} sx={{ height: '500px' }}>
                    <Suspense fallback={<Skeleton variant="rectangular" height={500} />}>
                      <MarketShareMap
                        selectedSchool={selectedSchool}
                        filteredSchools={filteredSchools}
                        esriData={esriData}
                        visibleDriveTimes={visibleDriveTimes}
                        schoolsEnrollmentData={schoolsEnrollmentData}
                        governanceFilter={governanceFilter}
                        selectedGrades={selectedGrades}
                        schoolTypeFilter={schoolTypeFilter}
                      />
                    </Suspense>
                  </Paper>
                </AccordionDetails>
              </Accordion>
  
              <Accordion 
                expanded={expandedSections.enrollment}
                onChange={handleAccordionChange('enrollment')}
                defaultExpanded
                sx={{ mt: 2 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="enrollment-content"
                  id="enrollment-header"
                  sx={{
                    '& .MuiAccordionSummary-content': {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    width: '100%',
                    mb: expandedSections.enrollment ? 1 : 0
                  }}>
                    <BarChartIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Nearby Schools Comparison</Typography>
                    {!expandedSections.enrollment && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ml: 2,
                          color: 'text.secondary',
                          flexGrow: 1
                        }}
                      >
                        Comparative five-year trends for nearby schools
                      </Typography>
                    )}
                  </Box>
                  {expandedSections.enrollment && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        ml: 4
                      }}
                    >
                      Comparative five-year trends for nearby schools
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Suspense fallback={<Skeleton variant="rectangular" height={500} />}>
                    <EnrollmentComparisonChart
                      filteredSchools={filteredSchools}
                      schoolsEnrollmentData={schoolsEnrollmentData}
                      selectedGrades={selectedGrades}
                      governanceFilter={governanceFilter}
                      schoolTypeFilter={schoolTypeFilter}
                    />
                  </Suspense>
                </AccordionDetails>
              </Accordion>
              <Accordion 
                expanded={expandedSections.actions}
                onChange={handleAccordionChange('actions')}
                defaultExpanded
                sx={{ mt: 2 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="actions-content"
                  id="actions-header"
                  sx={{
                    '& .MuiAccordionSummary-content': {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    width: '100%',
                    mb: expandedSections.actions ? 1 : 0
                  }}>
                    <Explore sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Now What?</Typography>
                    {!expandedSections.actions && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ml: 2,
                          color: 'text.secondary',
                          flexGrow: 1
                        }}
                      >
                        Continue to explore with other enrollment planning tools
                      </Typography>
                    )}
                  </Box>
                  {expandedSections.actions && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        ml: 0
                      }}
                    >
                      Continue to explore with other enrollment planning tools
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => navigate('/geographic-explorer')}
                        sx={{
                          p: 3,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          textTransform: 'none'
                        }}
                      >
                        <MapsHomeWork 
                          sx={{ 
                            fontSize: 40,
                            mb: 2,
                            color: 'primary.main'
                          }} 
                        />
                        <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                          Geographic Explorer
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Explore demographic and enrollment trends in your community
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => navigate('/school-dashboard')}
                        sx={{
                          p: 3,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          textTransform: 'none'
                        }}
                      >
                        <AutoGraphOutlined 
                          sx={{ 
                            fontSize: 40,
                            mb: 2,
                            color: 'primary.main'
                          }} 
                        />
                        <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                          Projected Enrollment
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Explore historical and projected enrollment data for the next five years
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => navigate('/data-reports')}
                        sx={{
                          p: 3,
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          textTransform: 'none'
                        }}
                      >
                        <DescriptionOutlined 
                          sx={{ 
                            fontSize: 40,
                            mb: 2,
                            color: 'primary.main'
                          }} 
                        />
                        <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                          Data Reports
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Download detailed reports, including Esri demographic data for selected schools
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          ) : null}
        </>
      )}
    </Container>
  );
}

export default MarketShareAnalyzer;
